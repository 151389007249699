import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from 'react-bootstrap/Modal';
import { FaEdit, FaTrash, FaCamera, FaUpload, FaInfoCircle } from 'react-icons/fa';
import CLIENT_ORIGIN from '../config';
import FileInputButton from './FileInputButton';
import useCameraUpload from '../hooks/useCameraUpload';
import EditQuestionModal from './EditQuestionModal';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import '../styles/HomePage.css';

const HomePage = ({ accounts }) => {
    const [formData, setFormData] = useState({
        topic: '',
        age: '',
        specification: '',
        questionInstructions: '',
        numberOfQuestions: ''
    });
    const [questions, setQuestions] = useState([]);
    const [loadingGeneraraProv, setLoadingGenereraProv] = useState(false);
    const [loadingSparaProv, setLoadingSparaProv] = useState(false);
    const [filesGenerated, setFilesGenerated] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [showBanner, setShowBanner] = useState(true);
    const [errorBanner, setErrorBanner] = useState(null);
    const [progress, setProgress] = useState(0);
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [questionsGenerated, setQuestionsGenerated] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const navigate = useNavigate();
    const [downloadLinks, setDownloadLinks] = useState([]);

    const {
        images,
        cameraOpen,
        stream,
        handleImageUpload,
        handleRemoveImage,
        handleOpenCamera,
        handleCaptureImage,
    } = useCameraUpload();

    const inputStyle = 'input-style';

    const isMobile = () => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAddQuestion = async () => {
        if (questions.length > 0) {
            const confirmClear = window.confirm("Det finns redan frågor. Om du fortsätter kommer alla befintliga frågor att raderas. Vill du fortsätta?");
            if (!confirmClear) {
                return;
            }
        }

        setQuestions([]);

        if (!formData.topic) {
            setErrorBanner("Du måste ha ett  'Ämne' för provet.");
            return;
        }

        if (formData.age !== '' && (!Number.isInteger(Number(formData.age)) || Number(formData.age) < 0)) {
            setErrorBanner("Om du anger 'Ålder' så måste det vara ett positivt heltal.");
            return;
        }
        
        if (formData.numberOfQuestions === '' || !Number.isInteger(Number(formData.numberOfQuestions)) || Number(formData.numberOfQuestions) <= 0) {
            setErrorBanner("Antal frågor måste vara ett positivt heltal.");
            return;
        }

        setErrorBanner(null);
        console.log("New Question Generation request:", formData);

        setLoadingGenereraProv(true);
        setShowProgressModal(true);
        setProgress(0);
        try {
            const formDataToSend = new FormData();
            formDataToSend.set('topic', formData.topic);
            formDataToSend.set('age', formData.age);
            formDataToSend.set('specification', formData.specification);
            formDataToSend.set('questionInstructions', formData.questionInstructions);
            images.forEach((image) => {
                formDataToSend.append('images', image);
            });

            let tempquestions = JSON.stringify(questions.map(q => ({
                question: q.question,
                solution: q.solution,
                answer: q.answer
            })));

            const numberOfQuestions = Number(formData.numberOfQuestions) || 1;
            for (let i = 0; i < numberOfQuestions; i++) {
                formDataToSend.set('questions', tempquestions);
                const response = await axios.post(
                    `${CLIENT_ORIGIN}/api/generate-question`,
                    formDataToSend,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                const newQuestion = {
                    id: Date.now() + i,
                    question: response.data.question,
                    solution: response.data.solution,
                    answer: response.data.answer
                };
                setQuestions(prevQuestions => [...prevQuestions, newQuestion]);

                let tempquestionsArray = JSON.parse(tempquestions);
                tempquestionsArray.push({
                    question: response.data.question,
                    solution: response.data.solution,
                    answer: response.data.answer
                });
                tempquestions = JSON.stringify(tempquestionsArray);
                setProgress(((i + 1) / numberOfQuestions) * 100);
            }
            setQuestionsGenerated(true);
        } catch (error) {
            console.error('Error adding question:', error);
        } finally {
            setLoadingGenereraProv(false);
            setShowProgressModal(false);
        }
    };

    const handleEditQuestion = (id) => {
        const questionToEdit = questions.find(q => q.id === id);
        setCurrentQuestion(questionToEdit);
        setShowEditModal(true);
    };

    const handleSaveQuestion = (editedQuestion) => {
        setQuestions(questions.map(q => q.id === editedQuestion.id ? editedQuestion : q));
    };

    const handleDeleteQuestion = (id) => {
        setQuestions(questions.filter(q => q.id !== id));
    };

    const handleCreateTest = async () => {
        setLoadingSparaProv(true);
        try {
            const response = await axios.post(`${CLIENT_ORIGIN}/api/generate-files`, {
                topic: formData.topic,
                age: formData.age,
                questions: questions.map(q => ({
                    question: q.question,
                    solution: q.solution,
                    answer: q.answer
                }))
            });
            const newLink = {
                timestamp: new Date().toLocaleString(),
                tex: response.data.tex,
                pdf: response.data.pdf,
                topic: formData.topic,
                num_questions: formData.numberOfQuestions,
                age: formData.age
            };
            setDownloadLinks([newLink, ...downloadLinks]);
            setFilesGenerated(true);
        } catch (error) {
            console.error('Error generating files:', error);
        } finally {
            setLoadingSparaProv(false);
        }
    };

    const handleCorrectTestClick = (test) => {
        navigate('/correction', { state: { test } });
    };

    const containerStyle = 'container-style';
    const formContainerStyle = 'form-container-style';
    const formSectionStyle = 'form-section-style';
    const tableStyle = 'table-style';
    const buttonContainerStyle = 'button-container-style';
    const bodyComponentStyle = 'body-component-style';

    const infoModalContent = (
        <>
            <p>
                På denna sida genereras matematikprov. Du anger information om provet i varje fält och laddar upp bilder från exempelvis ditt läromaterial, som säkerställer att provet håller sig inom ramarna för vad dina elever lärt sig i relation till ämnet du anger.
            </p>
            <p>
                Ämne avser ämne inom matematiken (Volym, aritmetik, sannolikhet, geometri etc.)
            </p>
            <p>
                Antal frågor är antalet frågor du önskar ha på provet.
            </p>
            <p>
                Ålder avser ålder på eleverna. Tjänsten beräknar nu årskurs i förhållande till ålder i underkant, dvs att årskurs 5 motsvarar 11 år, årskurs 8 14 år osv.
            </p>
            <p>
                Övriga instruktioner kan vara allt från avgränsningar i ämnen som provet ska behandla till tema på provet eller justering av nivå på språket som provet använder sig av.
            </p>
            <p>
                När du klickar på Generera prov presenteras du till ett förslag på frågor där du kan lägga till och ta bort frågor i efterhand samt justera de genererade frågorna, antingen manuellt eller genom att be AI-stödet modifiera en fråga enligt dina instruktioner. När du är nöjd klickar du på spara prov och du får länkar till provet i LaTeX-format samt PDF-format.
            </p>
        </>
    );

    return (
        <div className="profileContent">
            {showBanner && (
                <Alert variant="warning" onClose={() => setShowBanner(false)} dismissible className="alert-banner">
                    Notera att detta fortfarande är en väldigt tidig iteration och kommer att förändras löpande.
                </Alert>
            )}
            {errorBanner && (
                <Alert variant="danger" onClose={() => setErrorBanner(null)} dismissible className="alert-banner">
                    {errorBanner}
                </Alert>
            )}
            <div className="info-icon-container">
                <Button variant="link" onClick={() => setShowInfoModal(true)} className="info-icon full-size">
                    <FaInfoCircle className="full-size" />
                </Button>
            </div>
            <div className={`${containerStyle} ${bodyComponentStyle}`}>
                <h5>
                    Välkommen {accounts[0].name}
                </h5>
                <p className="intro-text">
                    Det här är en prototyp på en generator av matematikprov för svensk skola. Det är fritt fram att använda den i den mån du finner lämpligt om du har fått access till denna sida. Feedback ges med fördel till <a href="mailto:martin.lindberg@deviera.se">martin.lindberg@deviera.se</a>.
                </p>
                <div className={`${formContainerStyle}`}>
                    <div className={`form-placeholder ${formSectionStyle}`}>
                        <p className="form-instructions">
                            Du kan ladda upp bilder från läroboken som underlag för provet om du vill. Detta är dock valfritt.
                        </p>
                        <div className="side-by-side">
                            {isMobile() && (
                                <Button variant="primary" onClick={handleOpenCamera} className="side-by-side-item">
                                    <FaCamera /> Ta en bild
                                </Button>
                            )}
                            <FileInputButton onFileChange={handleImageUpload} accept="image/*" buttonText="Ladda upp bilder" className="side-by-side-item" />
                        </div>
                        {cameraOpen && (
                            <div className="camera-view">
                                <video autoPlay playsInline ref={videoRef => {
                                    if (videoRef && stream) {
                                        videoRef.srcObject = stream;
                                    }
                                }} />
                                <div>
                                    <Button variant="primary" onClick={handleCaptureImage}>Ta Bild</Button>
                                </div>
                            </div>
                        )}
                        {images.length > 0 && (
                            <div className="image-table-container">
                                <div className="image-table-wrapper">
                                    <table className="image-table">
                                        <tbody>
                                            {images.map((image, index) => (
                                                <tr key={index}>
                                                    <td>{image.name}</td>
                                                    <td>
                                                        <Button variant="danger" onClick={() => handleRemoveImage(index)} className="remove-image-button">
                                                            <FaTrash />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        <form>
                            <div className="form-group">
                                <label>Ämne*:</label>
                                <input
                                    type="text"
                                    name="topic"
                                    value={formData.topic}
                                    onChange={handleInputChange}
                                    className={inputStyle}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Antal frågor*:</label>
                                <input
                                    type="number"
                                    name="numberOfQuestions"
                                    value={formData.numberOfQuestions}
                                    onChange={handleInputChange}
                                    className={inputStyle}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Ålder:</label>
                                <input
                                    type="number"
                                    name="age"
                                    value={formData.age}
                                    onChange={handleInputChange}
                                    className={inputStyle}
                                />
                            </div>
                            <div className="form-group">
                                <label>Instruktioner (ex. tema på provet):</label>
                                <input
                                    type="text"
                                    name="specification"
                                    value={formData.specification}
                                    onChange={handleInputChange}
                                    className={inputStyle}
                                />
                            </div>
                        </form>
                        <Button variant="primary" onClick={handleAddQuestion} className="generate-button">
                            {loadingGeneraraProv ? <Spinner animation="border" size="sm" /> : 'Generera'}
                        </Button>
                    </div>
                </div>
                {questionsGenerated && (
                    <>
                        <div className={`${tableStyle}`}>
                            <h5>Frågor</h5>
                            <div className="questions-table-wrapper">
                                <table className={`${tableStyle}`}>
                                    <thead>
                                        <tr>
                                            <th className="table-cell">Fråga</th>
                                            <th className="table-cell">Lösning</th>
                                            <th className="table-cell">Svar</th>
                                            <th className="table-cell">Åtgärder</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {questions.map((q) => (
                                            <tr key={q.id}>
                                                <td className="table-cell">
                                                    <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
                                                        {q.question}
                                                    </ReactMarkdown>
                                                </td>
                                                <td className="table-cell">
                                                    <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
                                                        {q.solution}
                                                    </ReactMarkdown>
                                                </td>
                                                <td className="table-cell">
                                                    <ReactMarkdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
                                                        {q.answer}
                                                    </ReactMarkdown>
                                                </td>
                                                <td className="table-cell">
                                                    <Button variant="link" onClick={() => handleEditQuestion(q.id)}>
                                                        <FaEdit />
                                                    </Button>
                                                    <Button variant="link" onClick={() => handleDeleteQuestion(q.id)}>
                                                        <FaTrash />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <EditQuestionModal
                                show={showEditModal}
                                handleClose={() => setShowEditModal(false)}
                                question={currentQuestion}
                                handleSave={handleSaveQuestion}
                                formData={formData}
                            />
                        </div>
                        <div className={`${buttonContainerStyle}`}>
                            <Button
                                variant="success"
                                onClick={handleCreateTest}
                                disabled={questions.length === 0}
                                className="save-test-button"
                            >
                                {loadingSparaProv ? <Spinner animation="border" size="sm" /> : 'Spara prov'}
                            </Button>
                        </div>
                        {filesGenerated && (
                            <div className={`${tableStyle}`}>
                                <h5>Skapade prov</h5>
                                <p className="created-tests-info">
                                    Här hittar du de prov du precis har skapat. Du kan hitta alla tidigare prov om du går till <a href="/tests">Tidigare prov</a> i topp menyn.
                                </p>
                                <div className="created-tests-table-wrapper">
                                    <table className="created-tests-table">
                                        <thead>
                                            <tr>
                                                <th className="table-cell">Tid</th>
                                                <th className="table-cell">Ämne</th>
                                                <th className="table-cell">Antal Frågor</th>
                                                <th className="table-cell">Ålder</th>
                                                <th className="table-cell">LaTeX-filen</th>
                                                <th className="table-cell">PDF-filen</th>
                                                <th className="table-cell">Rätta Prov</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {downloadLinks.map((link, index) => (
                                                <tr key={index}>
                                                    <td className="table-cell">{link.timestamp}</td>
                                                    <td className="table-cell">{link.topic}</td>
                                                    <td className="table-cell">{link.num_questions}</td>
                                                    <td className="table-cell">{link.age}</td>
                                                    <td className="table-cell">
                                                        <a href={link.tex} download> TEX</a>
                                                    </td>
                                                    <td className="table-cell">
                                                        <a href={link.pdf} target="_blank" rel="noopener noreferrer"> PDF</a>
                                                    </td>
                                                    <td className="table-cell">
                                                        <Button variant="link" onClick={() => handleCorrectTestClick(link)}>
                                                            <FaUpload />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <Modal show={showProgressModal} onHide={() => setShowProgressModal(false)} centered dialogClassName="modal-90w">
                <Modal.Header>
                    <Modal.Title>Genererar frågor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="progress-spinner-container">
                        <Spinner animation="border" />
                    </div>
                    <ProgressBar now={progress} label={`${Math.round(progress)}%`} />
                </Modal.Body>
            </Modal>
            <Modal show={showInfoModal} onHide={() => setShowInfoModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {infoModalContent}
                </Modal.Body>
            </Modal>
        </div>
    );
};

HomePage.propTypes = {
    accounts: PropTypes.array.isRequired,
};

export default HomePage;
