import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FaUpload } from 'react-icons/fa'; // Import the upload icon from react-icons
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CLIENT_ORIGIN from '../config'; // Import the backend URL configuration

const TestsPage = () => {
    const [tests, setTests] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchTests = async () => {
            try {
                const response = await axios.get(`${CLIENT_ORIGIN}/api/tests`);
                const sortedTests = response.data.sort((a, b) => b.timestamp.localeCompare(a.timestamp)); // Sort tests by timestamp
                setTests(sortedTests);
            } catch (error) {
                console.error('Error fetching tests:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTests();
    }, []);

    const handleCorrectTestClick = (test) => {
        navigate('/correction', { state: { test } }); // Navigate to CorrectionPage with selected test
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const formatTimestamp = (timestamp) => {
        const year = timestamp.substring(0, 4);
        const month = timestamp.substring(4, 6);
        const day = timestamp.substring(6, 8);
        const hour = timestamp.substring(8, 10);
        const minute = timestamp.substring(10, 12);
        const second = timestamp.substring(12, 14);
        const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
        return date.toLocaleString();
    };

    return (
        <div className="testsPage" style={{ textAlign: 'center', padding: '20px' }}>
            <h5>Tidigare Genererade Prov</h5>
            {loading ? (
                <Spinner animation="border" />
            ) : (
                <div style={{ overflowX: 'auto' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th className="table-cell">Tid</th>
                                <th className="table-cell">Ämne</th>
                                <th className="table-cell">LaTeX-filen</th>
                                <th className="table-cell">PDF-filen</th>
                                <th className="table-cell">Rätta Prov</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tests.map((test, index) => (
                                <tr key={index}>
                                    <td className="table-cell">{formatTimestamp(test.timestamp)}</td>
                                    <td className="table-cell">{capitalizeFirstLetter(test.topic)}</td>
                                    <td className="table-cell">
                                        {test.tex && <a href={test.tex} download>Download TEX</a>}
                                    </td>
                                    <td className="table-cell">
                                        {test.pdf && <a href={test.pdf} target="_blank" rel="noopener noreferrer">Download PDF</a>}
                                    </td>
                                    <td className="table-cell">
                                        <Button variant="link" onClick={() => handleCorrectTestClick(test)}>
                                            <FaUpload />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default TestsPage;
