import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

import { PageLayout } from './components/PageLayout';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import TestsPage from './components/TestsPage';
import CorrectionPage from './components/CorrectionPage';
import CorrectionBulkPage from './components/CorrectionBulkPage';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { appInsights } from './telemetryService';
import './App.css';

export default function App() {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const [showTrackingModal, setShowTrackingModal] = useState(false);

    useEffect(() => {
        if (isAuthenticated && accounts && accounts.length > 0) {
            appInsights.setAuthenticatedUserContext(accounts[0].username || accounts[0].email);
            appInsights.trackPageView();

            // Check if the user has been informed about tracking in this session
            const userInformed = sessionStorage.getItem('userInformed');
            if (!userInformed) {
                setShowTrackingModal(true);
                sessionStorage.setItem('userInformed', 'true');
            }
        }
        return () => {
            appInsights.clearAuthenticatedUserContext();
        };
    }, [isAuthenticated, accounts]);

    return (
        <>
            <div className="App">
                <AuthenticatedTemplate>
                    <BrowserRouter>
                        <PageLayout isAuthenticated={isAuthenticated} accounts={accounts} />
                        <Routes>
                            <Route path="/" element={<HomePage accounts={accounts} />} />
                            <Route path="/tests" element={<TestsPage />} />
                            <Route path="/correction" element={<CorrectionPage />} />
                            <Route path="/correction-bulk" element={<CorrectionBulkPage accounts={accounts} />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </BrowserRouter>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <LoginPage />
                </UnauthenticatedTemplate>

                <Modal show={showTrackingModal} onHide={() => setShowTrackingModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Information om användarspårning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Vi spårar användningen av denna tjänst på kontonivå för att förbättra användarupplevelsen och säkerställa tjänstens tillförlitlighet. Dina aktiviteter och interaktioner med tjänsten kommer att loggas. Om du har frågor eller vill att datan tas bort, kontakta martin.lindberg@deviera.se. Datan används enbart till att förbättra tjänsten.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => setShowTrackingModal(false)}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}