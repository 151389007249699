import React, { useState } from 'react';
import { Button, Table, Spinner, Alert } from 'react-bootstrap';
import { FaTrash, FaCamera } from 'react-icons/fa';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import CLIENT_ORIGIN from '../config';
import FileInputButton from './FileInputButton';
import useCameraUpload from '../hooks/useCameraUpload';
import '../styles/CorrectionBulkPage.css';

const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

const CorrectionBulkPage = ({ accounts }) => {
    const location = useLocation();
    const prepopulatedTest = location.state?.test || null;

    const [loading, setLoading] = useState(false);
    const [latexTest, setLatexTest] = useState(prepopulatedTest?.tex || '');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [students, setStudents] = useState([{ name: 'Elev 1', images: [] }]);
    const [successMessage, setSuccessMessage] = useState('');

    const {
        cameraOpen,
        stream,
        handleOpenCamera,
        handleCaptureImage,
    } = useCameraUpload();

    const handleStudentNameChange = (index, newName) => {
        const updatedStudents = [...students];
        updatedStudents[index].name = newName;
        setStudents(updatedStudents);
    };

    const handleStudentImageUpload = (index, newImages) => {
        const updatedStudents = [...students];
        updatedStudents[index].images = [...updatedStudents[index].images, ...newImages];
        setStudents(updatedStudents);
    };

    const handleRemoveStudentImage = (studentIndex, imageIndex) => {
        const updatedStudents = [...students];
        updatedStudents[studentIndex].images.splice(imageIndex, 1);
        setStudents(updatedStudents);
    };

    const handleAddStudent = () => {
        const newStudentIndex = students.length + 1;
        setStudents([...students, { name: `Elev ${newStudentIndex}`, images: [] }]);
    };

    const handleRemoveStudent = (index) => {
        const updatedStudents = students.filter((_, i) => i !== index);
        setStudents(updatedStudents);
    };

    const handleLatexUpload = (e) => setLatexTest(e.target.files[0]);
    const handleRemoveLatex = () => setLatexTest('');

    const handleSend = async () => {
        if (!latexTest || students.some(student => student.images.length === 0)) {
            setAlertMessage('Du måste inkludera minst ett prov och en bild på elevens svar innan du begär rättning.');
            setShowAlert(true);
            return;
        }
    
        setLoading(true);
        setShowAlert(false);
        setSuccessMessage('');
    
        const formData = new FormData();
    
        // Append latexTest to formData
        if (typeof latexTest === 'string') {
            formData.append('provPath', latexTest);
        } else {
            formData.append('prov', latexTest, latexTest.name);
        }
    
        // Append student data
        students.forEach((student, index) => {
            formData.append(`elever[${index}][namn]`, student.name);
            student.images.forEach((image, imageIndex) => {
                formData.append(`elever[${index}][bilder]`, image);
            });
        });
    
        
        formData.append('email', accounts[0].username || accounts[0].email); 
    
        try {
            await axios.post(
                `${CLIENT_ORIGIN}/api/evaluateTestBulk`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );
            setSuccessMessage('Rättning har påbörjats. Du kommer få ett mail med resultatet när det är klart. Om det inte kommit inom 2 timmar, vänligen kontakta martin.lindberg@deviera.se.');

            setLatexTest('');
            setStudents([{ name: 'Elev 1', images: [] }]);
        } catch (error) {
            console.error('Error sending bulk evaluation request:', error);
            setAlertMessage('Ett fel uppstod vid försök att skicka rättningen.');
            setShowAlert(true);
        }
    
        setLoading(false);
    };

    const getLatexTestName = () => (typeof latexTest === 'string' ? latexTest.split('/').pop() : latexTest.name);

    return (
        <div className="correctionPage">
            {showAlert && (
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            )}
            {successMessage && (
                <Alert variant="success" onClose={() => setSuccessMessage('')} dismissible>
                    {successMessage}
                </Alert>
            )}
            <div className="header">
                <h1>Provresultat</h1>
            </div>
            <div className="instructions-section" style={{ textAlign: 'left' }}>
                <h2>Instruktioner</h2>
                <p>Följ stegen nedan för att ladda upp och rätta ditt prov:</p>
                <ol>
                    <li>Välj vilket prov du vill rätta genom att ladda upp en LaTeX-fil eller använda det förvalda provet.</li>
                    <li>Ladda upp bilder på det tagna provet eller ta bilder med kameran.</li>
                    <li>Klicka på "Rätta" för att få en preliminär rättning av provet.</li>
                </ol>
                <h2>Välj prov att rätta</h2>
                <p>Det provet som kommer att rättas. Måste för närvarande vara i LaTeX-format (.tex).</p>
                {latexTest ? (
                    <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td><strong>{getLatexTestName()}</strong></td>
                                    <td className="text-center">
                                        <Button variant="danger" onClick={handleRemoveLatex}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div className="side-by-side">
                        <FileInputButton onFileChange={handleLatexUpload} accept=".tex" buttonText="Ladda upp LaTeX-fil" />
                    </div>
                )}
                <h2>Välj elevsvar att utvärdera</h2>
                <p>Ladda upp bilder på det tagna provet i .jpg-format eller ta bilder med kameran om du använder mobil.</p>
                {students.map((student, studentIndex) => (
                    <div key={studentIndex} className="student-section">
                        <div className="student-row">
                            <input
                                type="text"
                                value={student.name}
                                onChange={(e) => handleStudentNameChange(studentIndex, e.target.value)}
                                className="student-name-input"
                            />
                            {isMobile() && (
                                <Button variant="primary" onClick={handleOpenCamera} className="side-by-side-item">
                                    <FaCamera /> Ta en bild
                                </Button>
                            )}
                            <FileInputButton
                                onFileChange={(e) => handleStudentImageUpload(studentIndex, e.target.files)}
                                accept=".jpg"
                                buttonText="Ladda upp bilder"
                                className="side-by-side-item"
                            />
                            <Button variant="danger" onClick={() => handleRemoveStudent(studentIndex)} className="side-by-side-item">
                                <FaTrash />
                            </Button>
                        </div>
                        {student.images.length > 0 && (
                            <div style={{ overflowX: 'auto' }}>
                                <Table striped bordered hover>
                                    <tbody>
                                        {student.images.map((image, imageIndex) => (
                                            <tr key={imageIndex}>
                                                <td><strong>{image.name}</strong></td>
                                                <td className="text-center">
                                                    <Button variant="danger" onClick={() => handleRemoveStudentImage(studentIndex, imageIndex)}>
                                                        <FaTrash />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        )}
                    </div>
                ))}
                <Button variant="success" onClick={handleAddStudent}>
                    Lägg till Elev
                </Button>
                {cameraOpen && (
                    <div className="camera-view">
                        <video autoPlay playsInline ref={videoRef => {
                            if (videoRef && stream) {
                                videoRef.srcObject = stream;
                            }
                        }} />
                        <div>
                            <Button variant="primary" onClick={handleCaptureImage}>Ta Bild</Button>
                        </div>
                    </div>
                )}
            </div>
            <hr />
            <div className="footer-buttons">
                <Button className="correct-btn" variant="primary" onClick={handleSend} disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Rätta'}
                </Button>
            </div>
        </div>
    );
};

CorrectionBulkPage.propTypes = {
    accounts: PropTypes.array.isRequired,
};

export default CorrectionBulkPage;