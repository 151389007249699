import { useState } from 'react';

const useCameraUpload = () => {
    const [images, setImages] = useState([]);
    const [cameraOpen, setCameraOpen] = useState(false);
    const [stream, setStream] = useState(null);

    const handleImageUpload = (e) => setImages([...images, ...Array.from(e.target.files)]);
    const handleRemoveImage = (index) => setImages(images.filter((_, i) => i !== index));

    const handleOpenCamera = async () => {
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.capture = 'environment';
            input.onchange = (e) => setImages([...images, ...Array.from(e.target.files)]);
            input.click();
        } else {
            if (cameraOpen) {
                stream.getTracks().forEach((track) => track.stop());
                setCameraOpen(false);
                return;
            }
            try {
                const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
                setStream(videoStream);
                setCameraOpen(true);
            } catch (error) {
                console.error('Error accessing camera:', error);
            }
        }
    };

    const handleCaptureImage = () => {
        const video = document.querySelector('video');
        if (!video) {
            console.error('Video element not found');
            return;
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
            if (blob) {
                setImages([...images, new File([blob], `captured_${Date.now()}.jpg`, { type: 'image/jpeg' })]);
            }
        });
        stream.getTracks().forEach((track) => track.stop());
        setCameraOpen(false);
    };

    return {
        images,
        cameraOpen,
        stream,
        handleImageUpload,
        handleRemoveImage,
        handleOpenCamera,
        handleCaptureImage,
    };
};

export default useCameraUpload;
