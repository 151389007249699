import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import axios from 'axios';
import CLIENT_ORIGIN from '../config';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/EditQuestionModal.css';

const EditQuestionModal = ({ show, handleClose, question, handleSave, formData }) => {
    const [editedQuestion, setEditedQuestion] = useState({
        question: '',
        solution: '',
        answer: ''
    });
    const [key, setKey] = useState('manual');
    const [aiInput, setAiInput] = useState('');
    const [aiResponse, setAiResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (question) {
            setEditedQuestion(question);
            setAiResponse(null);
            setAiInput('');
        }
    }, [question]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedQuestion({
            ...editedQuestion,
            [name]: value
        });
    };

    const handleSaveClick = () => {
        if (key === 'ai' && aiResponse) {
            handleSave({ ...aiResponse, id: editedQuestion.id });
        } else {
            handleSave(editedQuestion);
        }
        handleClose();
    };

    const handleAiInputChange = (e) => {
        setAiInput(e.target.value);
    };

    const handleAiUpdateClick = async () => {
        setLoading(true); // Set loading to true
        try {
            const response = await axios.post(`${CLIENT_ORIGIN}/api/ai-update`, {
                question: editedQuestion,
                instruction: aiInput,
                topic: formData.topic, // Include topic
                age: formData.age, // Include age
                specification: formData.specification // Include specification
            });
            setAiResponse(response.data);
        } catch (error) {
            console.error('Error updating question with AI:', error);
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
            <Modal.Header closeButton>
                <Modal.Title>Redigera Fråga</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                        <Tab eventKey="manual" title="Manuell">
                            <Row>
                                <Col xs={12}>
                                    <div style={{ marginBottom: '15px' }}>
                                        <label>Fråga:</label>
                                        <textarea
                                            name="question"
                                            value={editedQuestion.question}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', height: '100px' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div style={{ marginBottom: '15px' }}>
                                        <label>Lösning:</label>
                                        <textarea
                                            name="solution"
                                            value={editedQuestion.solution}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', height: '100px' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div style={{ marginBottom: '15px' }}>
                                        <label>Svar:</label>
                                        <textarea
                                            name="answer"
                                            value={editedQuestion.answer}
                                            onChange={handleChange}
                                            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', height: '100px' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="ai" title="AI Uppdatering">
                            <Row>
                                <Col xs={12}>
                                    <div style={{ marginBottom: '15px' }}>
                                        <label>Önskemål om förändringar:</label>
                                        <textarea
                                            value={aiInput}
                                            onChange={handleAiInputChange}
                                            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', height: '100px' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Button variant="primary" onClick={handleAiUpdateClick} disabled={loading}>
                                        {loading ? 'Uppdaterar...' : 'Uppdatera Fråga'}
                                    </Button>
                                </Col>
                            </Row>
                            {aiResponse && (
                                <Row style={{ marginTop: '15px' }}>
                                    <Col xs={12}>
                                        <div style={{ marginBottom: '15px' }}>
                                            <label>Uppdaterad Fråga:</label>
                                            <textarea
                                                value={aiResponse.question}
                                                readOnly
                                                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', height: '100px' }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div style={{ marginBottom: '15px' }}>
                                            <label>Uppdaterad Lösning:</label>
                                            <textarea
                                                value={aiResponse.solution}
                                                readOnly
                                                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', height: '100px' }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div style={{ marginBottom: '15px' }}>
                                            <label>Uppdaterad Svar:</label>
                                            <textarea
                                                value={aiResponse.answer}
                                                readOnly
                                                style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', height: '100px' }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </Tab>
                    </Tabs>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Stäng
                </Button>
                <Button variant="primary" onClick={handleSaveClick}>
                    Spara Ändringar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

EditQuestionModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    question: PropTypes.object,
    handleSave: PropTypes.func.isRequired,
    formData: PropTypes.object.isRequired, // Add formData prop type
};

export default EditQuestionModal;
