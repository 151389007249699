import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin({ history: browserHistory });
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '79de6f7b-eb49-47a2-86af-3e1bf9890e8e',
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();

export { appInsights, reactPlugin, browserHistory };