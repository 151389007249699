import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FaFileUpload } from "react-icons/fa";
import '../styles/FileInputButton.css'; // Import the CSS file

const FileInputButton = ({ onFileChange, accept, buttonText }) => {
    const fileInputRef = useRef(null);

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="file-input-button">
            <Button variant="primary" onClick={handleFileInputClick} className="file-input-button">
                <FaFileUpload /> {buttonText}
            </Button>
            <input
                type="file"
                accept={accept}
                multiple
                onChange={onFileChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default FileInputButton;
