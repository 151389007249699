import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Spinner, ProgressBar, Accordion, Modal, Alert } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle, FaCircle, FaTrash, FaCamera } from 'react-icons/fa';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import CLIENT_ORIGIN from '../config';
import FileInputButton from './FileInputButton';
import useCameraUpload from '../hooks/useCameraUpload';
import '../styles/CorrectionPage.css'; // Update import path for CSS

const ACCEPTABLE_CONFIDENCE = 0.8;

const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

const CorrectionPage = () => {
    const location = useLocation();
    const prepopulatedTest = location.state?.test || null;

    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null);
    const [latexTest, setLatexTest] = useState(prepopulatedTest?.tex || '');
    const [showResultsModal, setShowResultsModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const {
        images,
        cameraOpen,
        stream,
        handleImageUpload,
        handleRemoveImage,
        handleOpenCamera,
        handleCaptureImage,
    } = useCameraUpload();

    const handleLatexUpload = (e) => setLatexTest(e.target.files[0]);
    const handleRemoveLatex = () => setLatexTest('');

    const handleSend = async () => {
        if (!latexTest || images.length === 0) {
            setAlertMessage('Du måste inkludera minst ett prov och en bild på elevens svar innan du begär rättning.');
            setShowAlert(true);
            return;
        }
    
        setLoading(true);
        setShowAlert(false);
        console.log('Sending latexTest:', latexTest);
    
        const formData = new FormData();
    
        // Append latexTest to formData
        if (typeof latexTest === 'string') {
            formData.append('provPath', latexTest);
        } else {
            formData.append('prov', latexTest, latexTest.name);
        }
    
        // Append images to formData
        images.forEach((image) => {
            formData.append('bilder', image);
        });
    
        try {
            const response = await axios.post(
                `${CLIENT_ORIGIN}/api/evaluateTest`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            setResults(response.data);
            setShowResultsModal(true);
        } catch (error) {
            console.error('Error sending images:', error);
        } finally {
            setLoading(false);
        }
    };

    const getSummaryData = () => {
        const { totalQuestions, answeredQuestions, correctAnswers } = results.summary;
        const unansweredQuestions = totalQuestions - answeredQuestions;
        const incorrectAnswers = answeredQuestions - correctAnswers;
        return {
            total: totalQuestions,
            correct: correctAnswers,
            incorrect: incorrectAnswers,
            unanswered: unansweredQuestions,
            correctPercentage: (correctAnswers / totalQuestions) * 100,
            incorrectPercentage: (incorrectAnswers / totalQuestions) * 100,
            unansweredPercentage: (unansweredQuestions / totalQuestions) * 100,
        };
    };

    const getConfidenceIcon = (confidence) => (
        <FaCircle
            color={confidence > ACCEPTABLE_CONFIDENCE ? 'green' : 'red'}
            title={`Confidence: ${confidence}`}
            className="icon-small"
        />
    );

    const getLatexTestName = () => (typeof latexTest === 'string' ? latexTest.split('/').pop() : latexTest.name);

    return (
        <div className="correctionPage">
            {showAlert && (
                <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            )}
            <div className="header">
                <h1>Provresultat</h1>
            </div>
            <div className="instructions-section" style={{ textAlign: 'left' }}>
                <h2>Instruktioner</h2>
                <p>Följ stegen nedan för att ladda upp och rätta ditt prov:</p>
                <ol>
                    <li>Välj vilket prov du vill rätta genom att ladda upp en LaTeX-fil eller använda det förvalda provet.</li>
                    <li>Ladda upp bilder på det tagna provet eller ta bilder med kameran.</li>
                    <li>Klicka på "Rätta" för att få en preliminär rättning av provet.</li>
                </ol>
                <h2>Välj prov att rätta</h2>
                <p>Det provet som kommer att rättas. Måste för närvarande vara i LaTeX-format (.tex).</p>
                {latexTest ? (
                    <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td><strong>{getLatexTestName()}</strong></td>
                                    <td className="text-center">
                                        <Button variant="danger" onClick={handleRemoveLatex}>
                                            <FaTrash />
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div className="side-by-side">
                        <FileInputButton onFileChange={handleLatexUpload} accept=".tex" buttonText="Ladda upp LaTeX-fil" />
                    </div>
                )}
                <h2>Välj elevsvar att utvärdera</h2>
                <p>Ladda upp bilder på det tagna provet i .jpg-format eller ta bilder med kameran om du använder mobil.</p>
                <div className="side-by-side">
                    {isMobile() && (
                        <Button variant="primary" onClick={handleOpenCamera} className="side-by-side-item">
                            <FaCamera /> Ta en bild
                        </Button>
                    )}
                    <FileInputButton onFileChange={handleImageUpload} accept=".jpg" buttonText="Ladda upp bilder" className="side-by-side-item" />
                </div>
                {cameraOpen && (
                    <div className="camera-view">
                        <video autoPlay playsInline ref={videoRef => {
                            if (videoRef && stream) {
                                videoRef.srcObject = stream;
                            }
                        }} />
                        <div>
                            <Button variant="primary" onClick={handleCaptureImage}>Ta Bild</Button>
                        </div>
                    </div>
                )}
                {images.length > 0 && (
                    <div style={{ overflowX: 'auto' }}>
                        <Table striped bordered hover>
                            <tbody>
                                {images.map((image, index) => (
                                    <tr key={index}>
                                        <td><strong>{image.name}</strong></td>
                                        <td className="text-center">
                                            <Button variant="danger" onClick={() => handleRemoveImage(index)}>
                                                <FaTrash />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
            <hr />
            <div className="footer-buttons">
                <Button className="correct-btn" variant="primary" onClick={handleSend} disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Rätta'}
                </Button>
            </div>
            {results && (
                <ResultsModal
                    show={showResultsModal}
                    onHide={() => setShowResultsModal(false)}
                    results={results}
                    prepopulatedTest={prepopulatedTest}
                    images={images}
                    getSummaryData={getSummaryData}
                    getConfidenceIcon={getConfidenceIcon}
                    handleSend={handleSend}
                    loading={loading}
                />
            )}
        </div>
    );
};

const ResultsModal = ({ show, onHide, results, prepopulatedTest, images, getSummaryData, getConfidenceIcon, handleSend, loading }) => {
    const lowConfidence = results.questions.some(question => question.confidence < ACCEPTABLE_CONFIDENCE);

    return (
        <Modal show={show} onHide={onHide} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Provresultat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {lowConfidence && (
                    <Alert variant="warning">
                        Några av svaren har låg tillförlitlighet. Vänligen granska dessa svar manuellt.
                    </Alert>
                )}
                <h2>Översikt av prestationen</h2>
                <div className="summary-section">
                    <p><strong>Totalt antal frågor:</strong> {getSummaryData().total}</p>
                    <p><strong>Rätta svar:</strong> {getSummaryData().correct}</p>
                    <p><strong>Felaktiga svar:</strong> {getSummaryData().incorrect}</p>
                    <p><strong>Obesvarade frågor:</strong> {getSummaryData().unanswered}</p>
                    <ProgressBar>
                        <ProgressBar striped variant="success" now={getSummaryData().correctPercentage} key={1} label={`${getSummaryData().correctPercentage.toFixed(2)}%`} />
                        <ProgressBar striped variant="danger" now={getSummaryData().incorrectPercentage} key={2} label={`${getSummaryData().incorrectPercentage.toFixed(2)}%`} />
                        <ProgressBar striped variant="secondary" now={getSummaryData().unansweredPercentage} key={3} label={`${getSummaryData().unansweredPercentage.toFixed(2)}%`} />
                    </ProgressBar>
                    <div className="progress-legend">
                        <span><FaCheckCircle color="green" /> Rätta svar</span>
                        <span><FaTimesCircle color="red" /> Felaktiga Svar</span>
                        <span><FaTimesCircle color="gray" /> Obesvarade frågor</span>
                    </div>
                </div>
                <h2>Feedback</h2>
                <Accordion id="feedback-section">
                    {results.questions.map((question, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>
                                <div className="question-header">
                                    {getConfidenceIcon(question.confidence, question.attempted)}
                                    {question.correct ? <FaCheckCircle color="green" /> : (question.attempted ? <FaTimesCircle color="red" /> : <FaTimesCircle color="gray" />)}
                                    {` Fråga ${index + 1}`}
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <p>{question.feedback}</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                <h2>Underlag för bedömningen</h2>
                <a href={prepopulatedTest?.pdf} target="_blank" rel="noopener noreferrer">Länk till det rättade provet</a>
                <Accordion>
                    <Accordion.Item eventKey="images">
                        <Accordion.Header>Rättningen är baserad på följande bilder</Accordion.Header>
                        <Accordion.Body>
                            <div className="image-previews">
                                {images.map((image, index) => (
                                    <img key={index} src={URL.createObjectURL(image)} alt={`Uploaded ${index}`} className="image-preview" />
                                ))}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer className="footer-buttons">
                <Button variant="secondary" onClick={onHide}>Stäng</Button>
                <Button variant="secondary" onClick={handleSend} disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Rätta Om'}
                </Button>
                <Button variant="primary" href={results.pdf_report_url} target="_blank" rel="noopener noreferrer">
                    Ladda ner PDF
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ResultsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    results: PropTypes.object.isRequired,
    prepopulatedTest: PropTypes.object,
    images: PropTypes.array.isRequired,
    getSummaryData: PropTypes.func.isRequired,
    getConfidenceIcon: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default CorrectionPage;